/**
 * https://kr.vuejs.org/v2/guide/mixins.html
 */

/* eslint-disable */

export default {
  methods: {
    common_getDateString(longValue) {
      if (longValue) {
        var date = new Date(longValue);

        return (
          date.getFullYear() +
          '/' +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '/' +
          ('0' + date.getDate()).slice(-2) +
          ' ' +
          ('0' + date.getHours()).slice(-2) +
          ':' +
          ('0' + date.getMinutes()).slice(-2) +
          ':' +
          ('0' + date.getSeconds()).slice(-2)
        );
      } else {
        return '';
      }
    },
    common_getDateString2(longValue) {
      if (longValue) {
        var date = new Date(longValue);

        return (
          date.getFullYear() +
          '.' +
          ('0' + (date.getMonth() + 1)).slice(-2) +
          '.' +
          ('0' + date.getDate()).slice(-2)
        );
      } else {
        return '';
      }
    },
    common_getTelephoneNoText(phoneNo) {
      if (!phoneNo) {
        return '';
      }

      var phoneDelimiter = '-';
      var result = '';

      if (phoneNo && phoneNo.length == 10 && !phoneNo.startsWith('010')) {
        result =
          phoneNo.substring(0, 2) +
          phoneDelimiter +
          phoneNo.substring(2, 6) +
          phoneDelimiter +
          phoneNo.substring(6, 10);
      } else if (phoneNo && phoneNo.length == 9 && !phoneNo.startsWith('010')) {
        result =
          phoneNo.substring(0, 2) +
          phoneDelimiter +
          phoneNo.substring(2, 5) +
          phoneDelimiter +
          phoneNo.substring(5, 9);
      } else if (phoneNo && phoneNo.length == 11) {
        result =
          phoneNo.substring(0, 3) +
          phoneDelimiter +
          phoneNo.substring(3, 7) +
          phoneDelimiter +
          phoneNo.substring(7, 11);
      }

      if (result) {
        return result;
      } else {
        return '';
      }
    },
    common_getMaskingName(name) {
      // 오리지널 이름
      var oriName = name;

      // 확인
      if (oriName == '' || !oriName || oriName == null) {
        return '';
      }

      // 마스킹 이름
      var maskingName;
      // 이름의 길이
      var nameLength = oriName.length;

      // 이름의 길이가 5개 미만이면 1개 빼고 * 표시
      if (nameLength < 5) {
        maskingName = oriName.replace(/(?<=.)./gi, '*');
      } else {
        // 나머지 2개 빼고 * 표시
        maskingName = oriName.replace(/(?<=.{2})./gi, '*');
      }

      return maskingName;
    },
    common_getMaskingPhoneNo(phoneNo) {
      if (!phoneNo) return;
      // 오리지널 휴대폰 번호(ex. 010-000-0000 or 010-0000-0000)
      var oriPhoneNo = phoneNo;
      // 오리지널 휴대폰 번호의 형식이 맞는지 아닌지 확인
      var matchPhoneNo = oriPhoneNo.match(/\d{2,3}-\d{3,4}-\d{4}/gi);
      // 마스킹 휴대폰 번호
      var maskingPhoneNo;

      // 확인
      if (matchPhoneNo == '' || !matchPhoneNo || matchPhoneNo == null) {
        return '';
      }

      // 010-000-0000
      if (/-[0-9]{3}-/.test(matchPhoneNo)) {
        maskingPhoneNo = oriPhoneNo
          .toString()
          .replace(
            matchPhoneNo,
            matchPhoneNo.toString().replace(/-[0-9]{3}-/g, '-***-'),
          );
      } else if (/-[0-9]{4}-/.test(matchPhoneNo)) {
        // 010-0000-0000
        maskingPhoneNo = oriPhoneNo
          .toString()
          .replace(
            matchPhoneNo,
            matchPhoneNo.toString().replace(/-[0-9]{4}-/g, '-****-'),
          );
      }

      return maskingPhoneNo;
    },
    common_getMaskingEmail(email) {
      if (!email) return;
      // 오리지널 이메일 주소
      var oriEmail = email;
      // 이메일 형식 확인
      var matchEmail = oriEmail.match(
        /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi,
      );
      // 마스킹 이메일
      var maskingEmail;

      // 확인
      if (matchEmail == '' || !matchEmail || matchEmail == null) {
        return '';
      }

      // email 아이디에서 3자리 수 뺀 수
      var emailIdLength = matchEmail.toString().split('@')[0].length - 3;

      maskingEmail = oriEmail
        .toString()
        .replace(new RegExp('.(?=.{0,' + emailIdLength + '}@)', 'g'), '*');

      return maskingEmail;
    },
    common_getFile(fileNm, noticeNo) {
      let celData = '-';
      if (fileNm != '' && fileNm != null) {
        celData = `<a href='${process.env.VUE_APP_URL_PORTAL}/support/notice/file/${noticeNo}' class='a-links'>${this.$i18n.t('notice.download')}</a>`;
      }
      return celData;
    },
    replaceCn(data) {
      return data ? data.replaceAll('\n', '<br />') : '-';
    },
  },
};
